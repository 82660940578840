import { useEffect } from "react";

const useScript = (url, js, async) => {
  useEffect(() => {
    const script = document.createElement("script");
    if (url !== undefined) {
      script.src = url;
    }
    if (js !== undefined) {
      script.innerText = js;
    }
    script.async = async;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, js, async]);
};

export default useScript;
