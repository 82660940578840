import * as React from "react";
import Layout from "../../components/Layout";
import useScript from "../../hooks/useScript";

const QPScroll = (props) => {
  useScript(
    "//ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js",
    undefined,
    false
  );
  useScript("//static.cigari.co.uk/scripts/qpscroll.js", undefined, false);
  useScript(
    undefined,
    `
    setTimeout(() => {
        $(document).ready(function(){
        if( window.innerWidth > 600 )
            $('body').qpScroll({
                imagesFolder: 'https://static.cigari.co.uk/simple/img/parallax/',
                imagesArray: [ 'a1.jpg' ],
                firstSpeed: 12,
                neighbourRatio: 1.02,
                });
    });
    $('.headerImage').append('<a href="https://github.com/oofaish/qpscroll" target="_blank" rel="noreferrer"><img class="forkMe pageSpecificElement" src="https://s3.amazonaws.com/github/ribbons/forkme_right_green_007200.png" alt="Fork me on GitHub"></a>');
    },
    500);
    `,
    true
  );

  return (
    <Layout>
      <div
        className="full-width-image-container margin-top-0 headerImage"
        style={{
          backgroundImage: `url('/img/becs-hill-climb-2022.jpeg')`,
        }}
      ></div>

      <div style={{ maxWidth: "700px", margin: "0 auto" }}>
        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          Quick Parallax Scoll (qpScroll)
        </h2>
        <p>
          <b>Does anyone still use jQuery?</b> Well if you do, here is a jQuery
          plugin that I wrote when I was young that uses a parallax background
          for any page or div. It is super simple to setup. It can be added to
          any existing page without the need to change the HTML mark up. This
          page uses a qpScroll.
        </p>
        <br></br>
        <p>I have kept it super simple – the three major settings are:</p>
        <br></br>
        <ul>
          <li>
            1. how much faster/slower do images move compared to the scroll
            speed
          </li>
          <li>
            2. how much faster/slower does each image scroll compared to one
            before it
          </li>
          <li>3. how much does each image cover the one before it</li>
        </ul>
        <p>
          <br></br>
          <br></br>
          Download the code{"  "}
          <a
            href="https://github.com/oofaish/qpScroll"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <br></br>
        <br></br>
        <p>And here is some Latin to see the full effect....</p>
        <br></br>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id
          varius nisl. Nunc consequat metus id turpis posuere, sit amet
          vulputate dui hendrerit. Curabitur quis aliquet diam. Proin varius
          nisi vitae nisi placerat, ac eleifend mauris tempor. Interdum et
          malesuada fames ac ante ipsum primis in faucibus. Vivamus vehicula id
          dui adipiscing viverra. Pellentesque ut libero vel eros vulputate
          lobortis. Aenean rutrum lobortis felis et condimentum. Sed faucibus at
          justo volutpat interdum. Mauris lacinia eget arcu quis euismod.
          Vestibulum eget sodales ligula. Sed purus libero, imperdiet at euismod
          ac, mollis in ipsum. Quisque quis sapien porttitor, rutrum lectus a,
          hendrerit enim. Cras ut aliquam diam. Ut a fermentum mauris, sed
          vestibulum libero. Suspendisse laoreet et sapien sit amet viverra.
          Aliquam dolor nunc, tristique cursus elit at, fermentum ultricies
          risus. Donec nec vestibulum tortor. Proin lobortis ut erat non auctor.
          Etiam pulvinar iaculis mi scelerisque volutpat. Vestibulum eget dui
          sit amet sapien egestas posuere et vitae dolor.
        </p>

        <p>
          Nullam eu sem id ante tempus feugiat. Nunc ut nisi non velit accumsan
          semper vel sed mi. Sed vitae lacus euismod, ornare quam ac, sodales
          arcu. Donec feugiat in leo et vehicula. Sed id diam ut leo vulputate
          aliquet vitae vel elit. Proin sollicitudin sapien sed congue
          condimentum. Suspendisse bibendum risus ac tempor consectetur.
        </p>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id
          varius nisl. Nunc consequat metus id turpis posuere, sit amet
          vulputate dui hendrerit. Curabitur quis aliquet diam. Proin varius
          nisi vitae nisi placerat, ac eleifend mauris tempor. Interdum et
          malesuada fames ac ante ipsum primis in faucibus. Vivamus vehicula id
          dui adipiscing viverra. Pellentesque ut libero vel eros vulputate
          lobortis. Aenean rutrum lobortis felis et condimentum. Sed faucibus at
          justo volutpat interdum. Mauris lacinia eget arcu quis euismod.
          Vestibulum eget sodales ligula. Sed purus libero, imperdiet at euismod
          ac, mollis in ipsum. Quisque quis sapien porttitor, rutrum lectus a,
          hendrerit enim. Cras ut aliquam diam.
        </p>

        <p>
          Ut a fermentum mauris, sed vestibulum libero. Suspendisse laoreet et
          sapien sit amet viverra. Aliquam dolor nunc, tristique cursus elit at,
          fermentum ultricies risus. Donec nec vestibulum tortor. Proin lobortis
          ut erat non auctor. Etiam pulvinar iaculis mi scelerisque volutpat.
          Vestibulum eget dui sit amet sapien egestas posuere et vitae dolor.
        </p>

        <p>
          Nullam eu sem id ante tempus feugiat. Nunc ut nisi non velit accumsan
          semper vel sed mi. Sed vitae lacus euismod, ornare quam ac, sodales
          arcu. Donec feugiat in leo et vehicula. Sed id diam ut leo vulputate
          aliquet vitae vel elit. Proin sollicitudin sapien sed congue
          condimentum. Suspendisse bibendum risus ac tempor consectetur.
        </p>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id
          varius nisl. Nunc consequat metus id turpis posuere, sit amet
          vulputate dui hendrerit. Curabitur quis aliquet diam. Proin varius
          nisi vitae nisi placerat, ac eleifend mauris tempor. Interdum et
          malesuada fames ac ante ipsum primis in faucibus. Vivamus vehicula id
          dui adipiscing viverra. Pellentesque ut libero vel eros vulputate
          lobortis. Aenean rutrum lobortis felis et condimentum. Sed faucibus at
          justo volutpat interdum. Mauris lacinia eget arcu quis euismod.
          Vestibulum eget sodales ligula. Sed purus libero, imperdiet at euismod
          ac, mollis in ipsum. Quisque quis sapien porttitor, rutrum lectus a,
          hendrerit enim. Cras ut aliquam diam.
        </p>

        <p>
          Ut a fermentum mauris, sed vestibulum libero. Suspendisse laoreet et
          sapien sit amet viverra. Aliquam dolor nunc, tristique cursus elit at,
          fermentum ultricies risus. Donec nec vestibulum tortor. Proin lobortis
          ut erat non auctor. Etiam pulvinar iaculis mi scelerisque volutpat.
          Vestibulum eget dui sit amet sapien egestas posuere et vitae dolor.
        </p>

        <p>
          Nullam eu sem id ante tempus feugiat. Nunc ut nisi non velit accumsan
          semper vel sed mi. Sed vitae lacus euismod, ornare quam ac, sodales
          arcu. Donec feugiat in leo et vehicula. Sed id diam ut leo vulputate
          aliquet vitae vel elit. Proin sollicitudin sapien sed congue
          condimentum. Suspendisse bibendum risus ac tempor consectetur.
        </p>

        <p>
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
          posuere cubilia Curae; Etiam ac consectetur libero. Integer id
          elementum quam, vehicula tincidunt ante. Cras non dignissim mi. Etiam
          tristique, risus tempus posuere fermentum, metus augue fringilla
          lacus, sit amet imperdiet leo dui lobortis massa. Phasellus lobortis
          massa nec mollis ornare. Nullam hendrerit arcu in lacus gravida, eu
          mollis sapien facilisis. Fusce non erat nec arcu varius consequat vel
          id dolor.
        </p>

        <p>
          Aliquam sollicitudin consectetur ipsum, in semper erat gravida vitae.
          Sed in nisl vitae felis vestibulum scelerisque. Nunc at ultrices
          turpis. Mauris dignissim odio ut congue dictum. Morbi semper
          adipiscing sem. Donec quis commodo erat, euismod tempus dui. Vivamus
          pulvinar, nisl quis dignissim euismod, nunc turpis auctor felis, at
          commodo nisi lorem a lacus. Maecenas vehicula neque nec est vulputate,
          et suscipit dui pellentesque. Aliquam tempus quis odio non dictum.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Fusce imperdiet sodales facilisis. In hac
          habitasse platea dictumst.
        </p>

        <p>
          Sed condimentum dapibus justo at rutrum. Interdum et malesuada fames
          ac ante ipsum primis in faucibus. Nunc lacinia id enim eu fermentum.
          Maecenas scelerisque scelerisque elit, eget venenatis diam feugiat ac.
          Duis lacinia ipsum ligula, quis pretium enim mollis a. Integer nec
          metus nibh. Curabitur pulvinar hendrerit ipsum, in bibendum nulla.
          Interdum et malesuada fames ac ante ipsum primis in faucibus. Quisque
          fringilla nisi at pretium interdum. Nam at mattis sem. Vestibulum quis
          velit ipsum. Cras eu sodales diam. Nunc ac bibendum lorem.
        </p>

        <p>
          Vivamus venenatis augue eget blandit malesuada. Sed hendrerit augue
          lorem, id suscipit purus scelerisque vel. Donec dapibus nibh in dolor
          laoreet, non posuere tortor posuere. Praesent volutpat neque mi, nec
          iaculis tellus bibendum in. Nullam eu dolor ornare, rhoncus arcu sed,
          molestie est. Nam condimentum odio et nunc commodo pellentesque.
          Praesent vel lectus facilisis, egestas leo volutpat, tincidunt enim.
          Sed et sollicitudin lacus. Nam facilisis nulla nec nulla bibendum, vel
          aliquet arcu fringilla. Suspendisse ut mauris non nisi volutpat ornare
          eu eu libero. Sed consectetur mattis placerat. Vivamus sed metus
          metus. Morbi non neque at nisl semper congue. Maecenas nulla elit,
          ultricies vitae mauris eget, aliquam porta massa.
        </p>

        <p>
          In et erat imperdiet, semper eros eu, ullamcorper lorem. Praesent
          cursus luctus arcu non lobortis. Nullam interdum neque id urna
          faucibus, vel interdum nibh commodo. Phasellus elementum volutpat
          purus laoreet tempus. Maecenas pharetra elit suscipit quam elementum
          facilisis. Vivamus turpis ligula, congue eget lorem vel, dignissim
          euismod augue. Aenean pulvinar blandit nunc, sed sollicitudin nunc
          faucibus et. Aliquam sed leo eget ipsum tempor bibendum. Etiam risus
          urna, sagittis sed egestas nec, interdum ut sem. Phasellus gravida nec
          diam eu consequat. Curabitur nec suscipit nisi. Donec auctor ipsum
          euismod nisi fringilla vehicula. Nunc ligula velit, egestas non erat
          ac, varius cursus libero.
        </p>

        <p>
          Nunc non viverra odio, et tempus quam. Sed pharetra malesuada augue
          sollicitudin imperdiet. Etiam at condimentum mauris. Sed cursus
          eleifend velit, sit amet convallis erat volutpat a. Nam laoreet tempor
          justo, et tempor leo eleifend quis. Etiam sed arcu scelerisque,
          blandit felis vel, tempor augue. Nullam feugiat orci nec odio
          vulputate, et facilisis dolor hendrerit. Fusce nisl turpis, feugiat
          non blandit eget, tincidunt et massa. Sed lacinia sem vel erat
          pharetra blandit. Vivamus a est et leo laoreet imperdiet in a eros.
          Nulla metus nibh, venenatis at mi vitae, lacinia gravida urna. Aliquam
          ultrices mauris in hendrerit ullamcorper.
        </p>

        <p>
          Curabitur erat arcu, imperdiet vitae porttitor in, lobortis malesuada
          lectus. Sed ultricies convallis metus, nec lacinia nunc eleifend a.
          Sed eu urna cursus, pellentesque odio ut, ultricies felis. Duis id
          lorem vel leo ornare venenatis. In turpis massa, congue quis rhoncus
          a, facilisis at sem. Pellentesque malesuada libero turpis. Quisque
          ornare odio ut urna laoreet tristique.
        </p>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id
          varius nisl. Nunc consequat metus id turpis posuere, sit amet
          vulputate dui hendrerit. Curabitur quis aliquet diam. Proin varius
          nisi vitae nisi placerat, ac eleifend mauris tempor. Interdum et
          malesuada fames ac ante ipsum primis in faucibus. Vivamus vehicula id
          dui adipiscing viverra. Pellentesque ut libero vel eros vulputate
          lobortis. Aenean rutrum lobortis felis et condimentum. Sed faucibus at
          justo volutpat interdum. Mauris lacinia eget arcu quis euismod.
          Vestibulum eget sodales ligula. Sed purus libero, imperdiet at euismod
          ac, mollis in ipsum. Quisque quis sapien porttitor, rutrum lectus a,
          hendrerit enim. Cras ut aliquam diam.
        </p>

        <p>
          Ut a fermentum mauris, sed vestibulum libero. Suspendisse laoreet et
          sapien sit amet viverra. Aliquam dolor nunc, tristique cursus elit at,
          fermentum ultricies risus. Donec nec vestibulum tortor. Proin lobortis
          ut erat non auctor. Etiam pulvinar iaculis mi scelerisque volutpat.
          Vestibulum eget dui sit amet sapien egestas posuere et vitae dolor.
        </p>

        <p>
          Nullam eu sem id ante tempus feugiat. Nunc ut nisi non velit accumsan
          semper vel sed mi. Sed vitae lacus euismod, ornare quam ac, sodales
          arcu. Donec feugiat in leo et vehicula. Sed id diam ut leo vulputate
          aliquet vitae vel elit. Proin sollicitudin sapien sed congue
          condimentum. Suspendisse bibendum risus ac tempor consectetur.
        </p>
      </div>
    </Layout>
  );
};

export default QPScroll;
